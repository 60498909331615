import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cube";
import "swiper/css/pagination";

import "./testimonials.css";
import {
  Restaurant1,
  Restaurant2,
  Restaurant3,
  Restaurant4,
  Restaurant5
} from "../restaurantImages";
// import required modules
import { EffectCube, Pagination, Autoplay } from "swiper/modules";

export default function App() {
  return (
    <div className="mt-5 md:px-10 py-10 px-4 bg-gray-100 ">
      {/* <h1 className="text-xl font-extrabold py-5 text-center">Our Gallery</h1> */}
      <div className="grid grid-cols-8 gap-4 mx-auto  ">
        <div className="md:col-span-4 lg:col-span-5 col-span-8  flex flex-col items-center justify-center px-2">
          <h1 className="font-extrabold text-xl ">
            Thoughtfully Curated Menus
          </h1>
          <h1 className="text-justify py-5 text-gray-600 font-semibold">
            Whether you are a dedicated carnivore or a vegetarian enthusiast,
            our thoughtfully curated menus cater to diverse palates. We offer a
            diverse range of non-vegetarian and vegetarian dishes, ensuring
            there's something to delight every taste bud.
          </h1>
          <h1 className="text-justify py-5 text-gray-600 font-semibold">
            Our starters are not just appetizers; they're the prelude to an
            extraordinary culinary journey. From crispy calamari rings to
            tantalizing vegetable spring rolls, each bite is designed to awaken
            your taste buds.
          </h1>
        </div>
        <div className="md:col-span-4 lg:col-span-3 col-span-8 py-5 px-2">
          <Swiper
            effect={"cube"}
            grabCursor={true}
            cubeEffect={{
              shadow: true,
              slideShadows: true,
              shadowOffset: 20,
              shadowScale: 0.94,
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={true}
            modules={[EffectCube, Pagination, Autoplay]}
            className="mySwiper"
          >
             <SwiperSlide>
              <img alt="bawarchi" src={Restaurant5} />
            </SwiperSlide>
            <SwiperSlide>
              <img alt="bawarchi" src={Restaurant3} />
            </SwiperSlide>

            <SwiperSlide>
              <img alt="bawarchi" src={Restaurant4} />
            </SwiperSlide>
            <SwiperSlide>
              <img alt="bawarchi" src={Restaurant1} />
            </SwiperSlide>
            <SwiperSlide>
              <img alt="bawarchi" src={Restaurant2} />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
}
