import "./App.css";
import Greeting from "./navbar/Greeting";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./navbar/Layout";
import { useEffect, useRef, useState, lazy, Suspense } from "react";
import Home from "./Home";

const CategoryPage = lazy(() =>
  import(/* webpackChunkName: "MobileFooter" */ "./CategoryPage")
);

function App() {
  const [smallScreen, setSmallScreen] = useState(window.innerWidth < 1064);

  const handleResize = (e) => {
    setSmallScreen(window.innerWidth < 1064);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const endRef = useRef(null);
  return (
    <BrowserRouter>
      {smallScreen ? (
        <Greeting bestRef={endRef} />
      ) : (
        <Layout bestRef={endRef} />
      )}

      <Routes>
        <Route
          path="/"
          element={
            <Suspense
              fallback={
                <div className="flex items-center justify-center">
                  Loading...
                </div>
              }
            >
              <Home endRef={endRef} smallScreen={smallScreen} />
            </Suspense>
          }
        />
        <Route
          path="/category/:id"
          element={
            <Suspense
              fallback={
                <div className="flex items-center justify-center">
                  Loading...
                </div>
              }
            >
              <CategoryPage endRef={endRef} smallScreen={smallScreen} />
            </Suspense>
          }
        />
      </Routes>

    
    </BrowserRouter>
  );
}

export default App;
